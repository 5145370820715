<template>
  <div class="pd-20">
    <a-page-header style="padding: 0" sub-title="">
      <div slot="title">
        <h1><i class="fas fa-calculator gradient"></i> PDQs</h1>
      </div>
      <div slot="extra">
        <a-button
          class="mr-10"
          type="primary"
          ghost
          @click="openPaymentLinkCardMachines = true"
        >
          <a-icon class="" type="link" /> LINK DE PAGAMENTO
        </a-button>

        <a-button type="primary" ghost @click="openCreateCardMachine = true">
          <i class="fas fa-calculator mr-10"></i> NOVA PDQ
        </a-button>
      </div>
    </a-page-header>

    <a-collapse
      class="travel-filters expandabl mt-0 mb-10"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template slot="header">
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>
        <a-row class="travel-filters mt-0 mb-0" :gutter="20">
          <a-col :span="6">
            <div class="travel-input">
              <label class="filled">Nome da maquininha</label>
              <a-input
                placeholder="Escreva o nome"
                v-model="cardMachines.filters.searchTerm"
                @change="getCardMachines()"
              />
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <a-table
      class="travel-table"
      :columns="[
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          class: 'id',
          sorter: true,
          width: 60,
        },
        {
          title: 'Nome ',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: { customRender: 'name' },
          width: 200,
        },
        {
          title: 'Número de Série',
          dataIndex: 'serial_number',
          key: 'serial_number',
          scopedSlots: { customRender: 'serial_number' },
          width: 150,
        },
        {
          title: 'Tipo',
          scopedSlots: { customRender: 'type' },
          width: 200,
        },
        {
          title: 'Empresa',
          dataIndex: 'company',
          key: 'company',
          width: 150,
          scopedSlots: { customRender: 'company' },
        },
        {
          title: 'Banco',
          dataIndex: 'bank',
          key: 'bank',
          scopedSlots: { customRender: 'bank' },
          width: 250,
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: 'Data/Hora',
          dataIndex: 'created',
          key: 'created',
          sorter: true,
          width: 150,
        },
        {
          title: '',
          key: 'action',
          align: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ]"
      :data-source="cardMachines.list"
      :loading="cardMachines.loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="cardMachinesTableChange"
    >
      <a slot="id" slot-scope="text"> {{ text }}</a>
      <div slot="company" slot-scope="record">
        {{ record.trading_name }}
      </div>
      <div slot="bank" slot-scope="record">
        {{ record.name }}
      </div>
      <div slot="type" slot-scope="record">
        {{ record.type | formatType }}
      </div>
      <template slot="action" slot-scope="record">
        <div class="actions-outer" style="position: relative">
          <a-tooltip placement="top" title="Editar">
            <a class="edit ml-15" @click="edit(record.id)">
              <a-icon type="edit-svg" />
            </a>
          </a-tooltip>
        </div>
      </template>
    </a-table>

    <div class="a-center mt-30" style="padding-bottom: 100px">
      <a-pagination
        show-size-changer
        :default-current="cardMachines.pagination.page"
        :total="cardMachines.pagination.total"
        @change="changeCardMachinesPage"
        @showSizeChange="changeCardMachinesPageSize"
      />
    </div>

    <a-drawer
      placement="right"
      width="640px"
      :closable="true"
      :visible="openCreateCardMachine"
      @close="openCreateCardMachine = false"
    >
      <template slot="title">
        <i class="fas fa-calculator cprimary mr-5"></i> NOVA PDQ
      </template>

      <EditCardMachineDefaultData
        v-if="openCreateCardMachine"
        @listCardMachines="listCardMachines"
      />
    </a-drawer>

    <a-drawer
      placement="right"
      width="500px"
      :closable="true"
      :visible="openPaymentLinkCardMachines"
      @close="openPaymentLinkCardMachines = false"
    >
      <template slot="title">
        <a-icon class="cprimary mr-5" type="link" /> LINK DE PAGAMENTO
      </template>
      <PaymentLinkCardMachineModal v-if="openPaymentLinkCardMachines" />
    </a-drawer>
  </div>
</template>

<script>
import EditCardMachineDefaultData from "@/components/card-machines/forms/EditCardMachineDefaultData.vue";
import PaymentLinkCardMachineModal from "@/components/card-machines/modal/PaymentLinkCardMachineModal.vue";
import cardMachinesMixins from "@/mixins/card-machines/cardMachinesMixins.js";

export default {
  name: "ListCardMachines",
  components: { EditCardMachineDefaultData, PaymentLinkCardMachineModal },
  mixins: [cardMachinesMixins],
  data() {
    return {
      openCreateCardMachine: false,
      openPaymentLinkCardMachines: false,
    };
  },
  beforeMount() {
    this.getCardMachines();
  },
  filters: {
    formatType(types) {
      let txt = "";
      JSON.parse(types).forEach((type) => {
        txt += type + ", ";
      });

      txt = txt.slice(0, -2);

      return txt;
    },
  },
  methods: {
    listCardMachines() {
      this.getCardMachines();
      this.openCreateCardMachine = false;
    },
    edit(id) {
      this.$router.push(`edit/${id}`);
    },
  },
};
</script>
